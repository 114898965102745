class DataWrapper {
  el;
  imageEl;
  dataEl;

  constructor(el) {
    this.el = el;
    this.imageEl = this.el.querySelector(".data-wrapper__image");
    this.dataEl = this.el.querySelector(".data-wrapper__items");

    // Ajustar la altura al cargar y redimensionar
    this.adjustHeight();
    window.addEventListener('resize', this.adjustHeight.bind(this));
  }

  adjustHeight() {
    // Solo ajustar si la ventana tiene un ancho de 992px o más
    if (window.innerWidth >= 992) {
      // Reiniciar la altura de la imagen para obtener la altura actual de dataEl
      this.imageEl.style.height = 'auto';

      // Obtener la altura de dataEl
      const dataElHeight = this.dataEl.offsetHeight;

      // Establecer la altura de imageEl para que coincida con la de dataEl
      this.imageEl.style.height = `${dataElHeight}px`;
    }else {
      this.imageEl.style.height = `initial`;
    }
  }
}

function initDataWrapper() {
  const wrapper = document.querySelector(".data-wrapper__row");
  new DataWrapper(wrapper);
}

window.addEventListener('load', initDataWrapper);
