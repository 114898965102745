(function () {
  const options = {
      threshold: [0.2]
  };

  let isIntersected = false;

  // Instantiate the IntersectionObserver class
  const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry => {
          // Checks if 50% or more of the element is visible in the viewport
          if (entry.intersectionRatio >= 0.2) {
              setCurrent(entry.target);
              observer.unobserve(entry.target);
          } 
      }));

      if (entries.isIntersecting && !isIntersected) {
        element.classList.add('active');
        isIntersected = true;
      }


  }, options);

  const setCurrent = (div) => {
      document.querySelectorAll('.active').forEach((el) => el.classList.remove('active'));
      div.classList.add('active', 'has-intersected');
  };

  const element = document.querySelectorAll('.intersect');
  element.forEach((div) => observer.observe(div));
})();