let paragraphs = [
  ...document.querySelectorAll(".animated-text__description-primary p"),
];
let spans = [];

paragraphs.forEach((paragraph) => {
  let htmlString = "";
  let pArray = paragraph.textContent.split("");

  for (let i = 0; i < pArray.length; i++) {
    htmlString += `<span>${pArray[i]}</span>`;
  }

  paragraph.innerHTML = htmlString;
});

spans = [
  ...document.querySelectorAll(".animated-text__description-primary span"),
];

function revealSpans() {
  for (let i = 0; i < spans.length; i++) {
    if (
      spans[i].parentElement.getBoundingClientRect().top <
      window.innerHeight / 2
    ) {
      let { left, top } = spans[i].getBoundingClientRect();
      top = top - window.innerHeight * 0.4;

      let opacityValue = 1 - (top * 0.01 + left * 0.001);
      opacityValue =
        opacityValue < 0.3
          ? 0.3
          : opacityValue > 1
          ? 1
          : opacityValue.toFixed(3);

      spans[i].style.opacity = opacityValue;

      // Convert opacity value (0.3 to 1) to a value for the color interpolation (0 to 1)
      let colorValue = (opacityValue - 0.3) / 0.7;

      // Interpolate between the two colors
      let startColor = [0, 0, 0]; // rgb values for #071C15
      let endColor = [37, 127, 95]; // rgb values for #257F5F

      let red = Math.round(
        startColor[0] + (endColor[0] - startColor[0]) * colorValue
      );
      let green = Math.round(
        startColor[1] + (endColor[1] - startColor[1]) * colorValue
      );
      let blue = Math.round(
        startColor[2] + (endColor[2] - startColor[2]) * colorValue
      );

      spans[i].style.color = `rgb(${red}, ${green}, ${blue})`;
    }
  }
}

window.addEventListener("scroll", revealSpans);
revealSpans();
