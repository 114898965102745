class LoadMorePosts {

    constructor(loadMorePostsContainer, loadMoreWrapper) {
        this.postsContainer = loadMorePostsContainer;
        this.loadMoreWrapper = loadMoreWrapper;
        this.loadMoreButton = this.loadMoreWrapper.querySelector('#load-more-posts');
        this.loadMoreProgressBar = this.loadMoreWrapper.querySelector('.progress-bar .bar');
        this.loadMoreOffset = this.loadMoreWrapper.querySelector('.offset');
        this.loadMoreTotal = this.loadMoreWrapper.querySelector('.total');

        this.itemsOffset = parseInt(this.postsContainer.dataset.itemsperpage);
        this.itemsTotal = parseInt(this.postsContainer.dataset.itemstotal);
        this.itemsPerPage = this.itemsOffset;
        this.lang = document.documentElement.lang;

        const url = window.location.href.split("/");
        this.requestUrl = url[0] + "//" + url[2] + "/api/tekpolio/v1/posts/";

				// Check if there are any more pages to load.
				if (this.itemsOffset >= this.itemsTotal) {
					this.updatePagination(true);
				}

				console.log(this.loadMoreWrapper)
        this.loadMoreButton.addEventListener('click', this.loadMoreHandler.bind(this));
    }

    loadMoreHandler() {
        console.log('Load more');


        this.postsContainer.classList.add('posts-container--loading');
        this.loadMoreButton.setAttribute('disabled', '');

        let queryString = '';

        const fetchUrl = new URL(this.requestUrl);

        if (this.itemsOffset) {
            fetchUrl.searchParams.append("offset", this.itemsOffset);
            if (queryString) {
                queryString += '&';
            }
            queryString += `offset=${this.itemsOffset}`;
        }

        fetchUrl.searchParams.append("perPage", this.itemsPerPage);
        if (queryString) {
            queryString += '&';
        }
        queryString += `perPage=${this.itemsPerPage}`;

        
        console.log(fetchUrl);

        fetch(fetchUrl, { method: 'GET' })
            .then((response) => response.json())
            .then((response) => {
                // Create and insert the markup.
                if (response.items) {
                    const markup = this.getItemMarkup(response.items);
                    this.postsContainer.insertAdjacentHTML('beforeend', markup);
                }

                if (response.itemsTotal <= this.itemsPerPage) {
                    this.itemsOffset = response.itemsTotal;
                } else {
                    this.itemsOffset = response.itemsOffset;
                }


                this.itemsTotal = response.itemsTotal;
                this.loadMoreProgressBar.style.width = `${Math.round((this.itemsOffset * 100) / this.itemsTotal)}%`;

                // Check if there are any more pages to load.
                if (this.itemsOffset >= this.itemsTotal) {
                    this.updatePagination(true);
                } else {
                    this.updatePagination();
                }
            })
            .finally(() => {
                this.postsContainer.classList.remove('posts-container--loading');
                this.loadMoreButton.removeAttribute('disabled');
            })
    }

    getItemMarkup(items) {
        return items.reduce((str, item) => str + item, '');
    }

    updatePagination(hiddeButton = false) {
        this.loadMoreOffset.innerHTML = this.itemsOffset;
        this.loadMoreTotal.innerHTML = this.itemsTotal;
        if (hiddeButton) {
					setTimeout(() => {
							this.loadMoreButton.setAttribute('hidden', '');
							this.loadMoreWrapper.classList.add('hidden');
					}, 0);
        } else {
            if (this.loadMoreButton.hasAttribute('hidden')) {
                this.loadMoreButton.removeAttribute('hidden');
								this.loadMoreWrapper.classList.remove('hidden');
            }
        }
    }
}

const initLoadMorePosts = () => {
    const loadMorePostsContainer = document.querySelector('#load-more-posts-container');
    const loadMoreButton = document.querySelector('#load-more-button-wrapper');

    if (loadMorePostsContainer && loadMoreButton) {
        new LoadMorePosts(loadMorePostsContainer, loadMoreButton);
    }
}

initLoadMorePosts();